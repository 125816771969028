import { shared } from "@/services/requests/requests";
import Cookie from "@/utils/cookies.utils";
import { useQuery } from "react-query";

function useProfile() {
  const { data, isLoading } = useQuery(["PROFILE"], shared.getProfile, {
    onError: () => {
      Cookie.delete("access_token");
    },
  });

  return { data, isLoading };
}

export default useProfile;
