import { Translations } from "@/types/global.types";




const translation: Record<string, Translations> = {
  siteName: {
    az: "Modabu",
    en: "Modabu",
  },
  sorted: {
    az: "Sıralanır...",
    en: " Sorting...",
  },
  searchInputText: {
    az: "Geyim, kateqoriya və ya marka...",
    en: "Clothing, category or brand...",
  },
  category: {
    az: "Kateqoriyalar",
    en: "Categories",
  },
  openStore: {
    az: "Mağaza aç",
    en: "Open a store",
  },
  backToShopping: {
    en: "Back to shopping",
    az: "Alış-verişə qayıt",
  },
  cart: {
    az: "Səbət",
    en: "Basket",
  },
  productAdded: {
    az: "Məhsul səbətə əlavə olundu",
    en: "Məhsul səbətə əlavə olundu"
  },
  selectSize: {
    az: "Zəhmət olmasa ölçü seçin",
    en: "Zəhmət olmasa ölçü seçin"
  },
  productFinished: {
    az: "Məhsul stokda tükendi",
    en: "Məhsul stokda tükendi"
  },
  orderProductsYouWantToBuy: {
    az: 'Almaq istədiyiniz məhsulları səbətə ataraq sifariş verin',
    en: "Order the products you want to buy by placing them in the basket"
  },
  doShopping: {
    az: "Alış-veriş et",
    en: "Do Shopping"
  },
  emptyCart: {
    az: "Səbət boşdur",
    en: "Cart is empty"
  },
  result: {
    az: "nəticə",
    en: "result",
  },

  brands: {
    az: "Brendlər",
    en: "Brands",
  },
  filters: {
    az: 'Filterlər',
    en: "Filters"
  },
  discountedProducts: {
    az: "Endirimli məhsullar",
    en: "Discount products",
  },
  newestFirst: {
    az: "Əvvəlcə yeni",
    en: "New at first",
  },
  search: {
    az: "Axtarış",
    en: "Search ",
  },
  oldestFirst: {
    az: "Əvvəlcə köhnə",
    en: "First the old",
  },
  priceAscending: {
    az: "Qiymət artan",
    en: "The price is increasing",
  },
  priceDescending: {
    az: "Qiymət azalan",
    en: "Price decreasing",
  },
  colors: {
    az: "Rənglər",
    en: "Colors",
  },
  size: {
    az: "Bədən",
    en: "Size",
  },
  price: {
    az: "Qiymət, AZN",
    en: "Price, AZN",
  },
  homePage: {
    az: "Ana səhifə",
    en: "Home Page",
  },
  azn: {
    az: "AZN",
    en: "USD",
  },
  store: {
    az: "Mağaza",
    en: "Store",
  },
  addToCart: {
    az: "Səbətə At",
    en: "Add to Cart",
  },
  findYourSize: {
    az: "Öz ölçünü tap",
    en: "Find your size",
  },
  quickBuy: {
    az: "Sürətli Al",
    en: "Quick Buy",
  },
  freeDelivery: {
    az: "Pulsuz çatdırılır",
    en: "Free Delivery",
  },
  inWorkingDays: {
    az: "2-3 iş günündə",
    en: "in 4-7 working days",
  },
  completeYourStyle: {
    az: "Stilini tamamla",
    en: "Complete Your Style",
  },
  about: {
    az: "Haqqında",
    en: "About",
  },
  delivery: {
    az: "Çatdırılma",
    en: "Delivery",
  },
  noCommentary: {
    az: "Məhsula şərh yazılmayıb",
    en: "No Commentary",
  },
  thoughts: {
    az: "Sifariş verdikdən sonra dəyərləndirmə və fikirlərinizi bizimlə bölüşə bilərsiniz.",
    en: "After placing an order, you can share your evaluation and opinion with us",
  },
  similarProducts: {
    az: "Oxşar məhsullar",
    en: "Similar Products",
  },
  worldOfFamousBrands: {
    az: "Məşhur brendlər və məhsulların dünyası",
    en: "World Of Famous Brands",
  },
  sendMessage: {
    az: "Mesaj yaz",
    en: "Send Message",
  },
  aboutUs: {
    az: "Haqqımızda",
    en: "About Us",
  },
  help: {
    az: "Kömək",
    en: "Help",
  },
  termsOfUse: {
    az: "İstifadə şərtləri",
    en: "Terms Of Use",
  },
  privacyPolicy: {
    az: "Məxfilik siyasəti",
    en: "Privacy Policy",
  },
  refund: {
    az: "Geri Qaytarma",
    en: "Geri Qaytarma",
  },
  cancelOrder: {
    az: "Sifarişin ləğvi",
    en: "Sifarişin ləğvi",
  },
  allRightsReserved: {
    az: "Bütün hüquqlar qorunur",
    en: "All Rights Reserved",
  },
  comments: {
    az: "Şərhlər",
    en: "Comments",
  },
  comment: {
    az: "şərh",
    en: "comment",
  },
  deliveryContent: {
    az: "Sifarişinizi saytımızda rəsmiləşdirdikdən sonra, bağlamanızı 4-7 gün ərzində seçdiyiniz gəl al nöqtəsindən təhvil ala bilərsiniz. *Sifarişinizi Smart Customs-da bəyan etməyi unutmayın!",
    en: "After formalizing your order on our website, delivery is delivered to the address free of charge within 4-7 days",
  },
  deliveredToAddress: {
    az: "Ünvana çatdırılır",
    en: "Delivered To Address",
  },
  district: {
    az: "Rayon",
    en: "District",
  },
  productCount: {
    az: "Məhsulların sayını müəyyən edib sifariş edə bilərsiniz",
    en: "You can determine the number of products and order",
  },
  outOfStock: {
    az: "Stok bitib",
    en: "Out Of Stock",
  },
  product: {
    az: "məhsul",
    en: "Product",
  },
  orderSummary: {
    az: "Sifariş yekunu",
    en: "Order Summary",
  },
  cargo: {
    az: "Kargo",
    en: "Cargo",
  },
  firstNameError: {
    "az": "Ad boş qoyula bilməz",
    "en": "First name is required"
  },
  lastNameError: {
    "az": "Soyad boş qoyula bilməz",
    "en": "Last name is required"
  },
  phoneError: {
    "az": "Mobil nömrə ən azı 9 rəqəm olmalıdır",
    "en": "Phone number must be at least 9 digits"
  },
  addressAdded: {
    az: "Ünvan əlavə olundu",
    en: "Address added"
  },
  cityError: {
    "az": "Şəhər boş qoyula bilməz",
    "en": "City is required"
  },
  addressError: {
    "az": "Adress boş qoyula bilməz",
    "en": "Address is required"
  },
  addressUpdated: {
    az: "Ünvan yeniləndi",
    en: "Address updated"
  },
  titleError: {
    "az": "Adress başlıqı boş qoyula bilməz",
    "en": "Address title is required"
  },
  findCode: {
    "az": "Fin kod",
    "en": "Fin code"
  },
  finCodeError: {
    "az": "Fin Kod  boş qoyula bilməz",
    "en": "Fin Code is required"
  },
  districtError: {
    "az": "Rayon boş qoyula bilməz",
    "en": "District is required"
  },
  saveAddress: {
    az: "Yadda saxla",
    en: "Save",
  },
  completeOrder: {
    az: "Sifarişi tamamla",
    en: "Complete Order",
  },
  productValue: {
    az: "Məhsulların dəyəri",
    en: "Product Value",
  },
  discount: {
    az: "Endirim",
    en: "Discount",
  },
  total: {
    az: "Yekun",
    en: "Total",
  },
  placeOrder: {
    az: "Sifariş et",
    en: "Make Order",
  },
  registrationRequired: {
    az: "Qeydiyyat tələb olunur",
    en: "Registration is Required",
  },
  register: {
    az: "Qeydiyyat",
    en: "Register",
  },
  quantity: {
    az: "ədəd",
    en: "quantity",
  },
  firstName: {
    az: "Ad",
    en: "Name",
  },
  lastName: {
    az: "Soyad",
    en: "Last Name",
  },
  mobile: {
    az: "Mobil",
    en: "Mobile",
  },
  city: {
    az: "Şəhər",
    en: "City",
  },
  fullAddress: {
    az: "Tam ünvan",
    en: "Full Address",
  },
  paymentMethod: {
    az: "Ödəniş növü",
    en: "Payment Method",
  },
  payment: {
    az: "Ödəniş",
    en: "Payment",
  },
  cash: {
    az: "Nəğd",
    en: "Cash",
  },
  byCard: {
    az: "Kartla",
    en: "By Card",
  },
  freeDeliveryNotice: {
    az: "Pulsuz çatdırılma!",
    en: " Free Delivery Notice!",
  },
  deliveryAfterOrder: {
    az: "Sifariş olunduqdan sonra məhsullar 2-3 gün ərzində ödənişsiz çatdırılır.",
    en: "Products are delivered within 4-7 days after ordering.",
  },
  returnToShopping: {
    az: "Alış-verişə qayıt",
    en: "Back to shopping",
  },
  defineProductCount: {
    az: "Məhsulların sayını müəyyən edib sifariş edə bilərsiniz",
    en: "You can determine the number of products and order",
  },
  finCode: {
    az: "FİN kod",
    en: "FİN Code",
  },
  identityContent: {
    az: "şəxsiyyət vəsiqəsində qeyd olunan",
    en: "mentioned in the identity card",
  },
  mobileNumber: {
    az: "Mobil nömrə",
    en: "Mobile Number",
  },
  deliveryInfo: {
    az: "Çatdırlıma məlumatı",
    en: "Delivery İnfo",
  },
  choosePickUpPoint: {
    az: "Gəl al nöqtəsi seçin",
    en: "Choose Pick Up Point",
  },
  deliverToAddress: {
    az: "Ünvana çatdırılsın",
    en: "Deliver To Address",
  },
  selectAddress: {
    az: "Ünvan seçin",
    en: "Select Address",
  },
  placeOrderAfterInfo: {
    az: "Məlumatlarınızı qeyd etdikdən sonra sifariş edin",
    en: "Place your order after entering your details",
  },
  pickUpPoint: {
    az: " Gəl al nöqtəsini seçin ",
    en: " Select Pick Up Point ",
  },
  region: {
    az: "Rayon",
    en: "Region",
  },
  cargoContent: {
    az: "Karqo paketinin etibarlı şəkildə sənə çatması üçün prospekt, küçə, bina və mərtəbə məlumatlarını tam daxil etməlisiniz.",
    en: "In order for the cargo package to reach you safely, you must enter the street, street, building and floor information.",
  },
  address: {
    az: "Ünvan",
    en: "Address",
  },
  pickUpPointContent: {
    az: "Zəhmət olmasa aşağıda sizə yaxın olan gəl al nöqtəsini qeyd edin",
    en: "Please note the nearest pick-up point below",
  },
  welcome: {
    az: "Xoş gəlmisiniz!",
    en: "Welcome!",
  },
  registerContent: {
    az: "Hesabınıza daxil olun və yeni hesab yaradın",
    en: "Log in to your account and create a new account",
  },
  logIn: {
    az: "Daxil ol",
    en: "Login",
  },
  password: {
    az: "Şifrə",
    en: "Password",
  },
  forgotPassword: {
    az: "Şifrəni unutmusan?",
    en: "Forgot Password?",
  },
  next: {
    az: "Növbəti",
    en: "Next",
  },
  smsCode: {
    az: "SMS kod",
    en: "SMS Code",
  },
  enterOtp: {
    az: "Nömrənizə gələn SMS kodu daxil edin",
    en: "Enter the SMS code sent to your number",
  },
  confirm: {
    az: "Təsdiqlə",
    en: "Confirm",
  },
  wrongOtpCode: {
    az: "Doğrulama kodu düzgün deyil",
    en: "The verification code is incorrect",
  },
  myOrders: {
    az: "Sifarişlərim",
    en: "My Orders",
  },
  customerCode: {
    az: "Müştəri kodu",
    en: "Customer Code",
  },
  modabuUser: {
    az: "Modabu istifadəçisi",
    en: "Modabu user",
  },
  orderNumber: {
    az: "Sifariş nömrəsi",
    en: "Order Number",
  },
  productQuantity: {
    az: "Məhsul Sayı",
    en: "Product Quantity",
  },
  addressDeleted: {
    az: "Ünvan silindi",
    en: "Address deleted"
  },
  viewDetails: {
    az: "Ətraflı bax",
    en: "View details ",
  },
  look: {
    az: "Bax",
    en: "Look",
  },
  detailed: {
    az: "Ətraflı",
    en: "Detailed",
  },
  products: {
    az: "Məhsullar",
    en: "Products",
  },
  date: {
    az: "Tarix",
    en: "Date",
  },
  totalAmount: {
    az: "Cəm məbləğ",
    en: "Total Amount",
  },
  favorites: {
    az: "Sevimlilər",
    en: "Favorites",
  },
  addresses: {
    az: "Ünvanlar",
    en: "Addresses",
  },
  addAddress: {
    az: "Ünvan əlavə et",
    en: "Add Address",
  },
  addressTitle: {
    az: "Address başlıqı",
    en: 'Address Title'
  },
  updateAddress: {
    az: "Ünvanı yenilə",
    en: "Update Address",
  },
  accountInfoAndPasswordUpdate: {
    az: "Sizin hesab məlumatlarınız və şifrə yeniləmə",
    en: "Account Info And Password Update",
  },
  accountDetails: {
    az: "Hesab məlumatları",
    en: "Account Details",
  },
  optional: {
    az: "məcburi deyil",
    en: "Optional",
  },
  save: {
    az: "Yadda saxla",
    en: "Save ase",
  },
  settings: {
    az: "Tənzimləmələr",
    en: "Settings",
  },
  logOut: {
    az: " Çıxış et",
    en: "Log out"
  },
  email: {
    az: "Email",
    en: "Email",
  },
  currentPassword: {
    az: "Hazırki şifrə",
    en: "Current Password",
  },
  newPassword: {
    az: "Yeni şifrə",
    en: " New password ",
  },
  edit: {
    az: "Düzəliş et",
    en: "Edit",
  },
  passwordType: {
    az: "Hərf və rəqəm daxil olmaqla ən azı 6 simvol",
    en: "At least 6 characters including letters and numbers",
  },
  finalAmount: {
    az: "Yekun məbləğ",
    en: "Final Amount",
  },
  myAccount: {
    az: "Hesabım",
    en: "My Account",
  },
  orderDetails: {
    az: "Sifariş detalları",
    en: "Order Details",
  },
  delete: {
    az: "Sil",
    en: "Delete",
  },
};


export default translation
