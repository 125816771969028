import { IAddressResponse, IDataWithPagination, IProductList } from "@/types/global.types";
import { create } from "zustand";
import { persist, createJSONStorage } from "zustand/middleware";

interface IUseCategory {
  categoryId: string | null;
  setCategoryId: (val: string) => void;
}

export const useCategory = create(
  persist<IUseCategory>(
    (set) => ({
      categoryId: null,
      setCategoryId: (val) =>
        set((state) => ({
          categoryId: val,
        })),
    }),
    {
      name: "categoryId",
      storage: createJSONStorage(() => localStorage),
    }
  )
);

interface IAddressDataOptions {
  data: IAddressResponse | null;
  id: number | null;
  setAddressDataByIndex: (val: IAddressResponse | null) => void;
  setIdByIndex: (val: number | null) => void;
}

export const useAddressDataByIndex = create<IAddressDataOptions>((set) => ({
  data: null,
  id: null,
  setAddressDataByIndex: (data) =>
    set({
      data,
    }),
  setIdByIndex: (id) =>
    set({
      id,
    }),
}));

type SelectedMarker = {
  lat: number;
  lng: number;
  iconUrl: string;
  isOpen: boolean;
  info?: string;
} | null

interface MarkersState {
  markersCount: number;
  selectedMarker: SelectedMarker,
  setMarkers: (markersOptions: SelectedMarker | null) => void;
  setMarkersCount: (val: number) => void;
}

export const useMarkers = create<MarkersState>((set) => ({
  markersCount: 0,
  selectedMarker: null,
  setMarkersCount: (val) =>
    set({
      markersCount: val,
    }),
  setMarkers: (val) =>
    set({
      selectedMarker: val,
    }),
}));


interface ISearchBar {
  isShow: boolean;
  setIsShow: (val: boolean) => void;
}

export const useSearchBar = create<ISearchBar>((set) => ({
  isShow: false,
  setIsShow: (isShow) =>
    set({
      isShow
    }),
}));




interface IProductsListStore {
  productListData: IDataWithPagination<IProductList[]>;
  page: number;
  setProductListData: (data: IProductsListStore['productListData']) => void;
  setPage: (page: number) => void;
  destroyPageCount: () => void;
  destroyProductListData: () => void
}

export const useProductListData = create<IProductsListStore>((set) => ({
  productListData: {
    data: [],
    pagination: {
      current_page: 0,
      last_page: 0,
      total: 0,
      per_page: 0
    }
  },
  page: 1,
  setPage: (page) =>
    set((state) => ({
      page: state.page + page
    })),
  destroyPageCount: () =>
    set({
      page: 1
    }),
  destroyProductListData: () =>
    set((state) => ({
      ...state,
      productListData: {
        data: [],
        pagination: {
          current_page: 0,
          last_page: 0,
          total: 0,
          per_page: 0
        }
      }
    })),
  setProductListData: (data) => set((state) => {
    const existingIds = new Set(state.productListData.data.map(item => item.id));
    const filteredData = data.data.filter(item => !existingIds.has(item.id));

    const customData = {
      ...state.productListData,
      data: [...state.productListData.data, ...filteredData],
      pagination: data.pagination
    };

    return {
      ...state,
      productListData: customData
    };
  })
}));
